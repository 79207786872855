.page-sidebar {
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 1px rgba(17, 20, 30, 0.1);
  min-height: calc(100vh - 160px);
  height: 100%;

  &.with-top-padding {
    padding-top: 50px;
    margin-bottom: 50px;
  }

  &.with-bottom-margin{
    margin-bottom: 100px;
  }
}
