@import 'assets/scss/_variables.scss';

.spinner-with-white-background {
  background: #fff;

  .ant-spin-blur {
    opacity: 0;
  }
}

.form-group-header {
  display: flex;
  justify-content: center;
}


.phone-number-wrapper {
  height: 32px;

  .ant-input-group.ant-input-group-compact::before {
    display: none;
  }

  .ant-input-group.ant-input-group-compact {
    display: flex;
  }
}

.vertical-form-item{
  .ant-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.ant-input-group.ant-input-group-compact {
  .ant-form-item {
    margin-bottom: 0;
  }
}

