.program-list-wrapper {
  .ant-input-group-wrapper {
    width: 264px;
  }
}

.my-department-outer-table {
  .ant-table-expanded-row-level-1 {
    > .ant-table-cell {
      padding: 0;
      .ant-table-row-expand-icon-cell {
        padding-left: 48px;
      }
    }
  }

  .my-department-inner-table {
    .ant-table-wrapper {
      margin-top: 0;

      .ant-pagination {
        display: none;
      }

      .ant-table-expanded-row-level-1 > .ant-table-cell {
        background-color: #E7E8EC;
        padding-left: 0;
        padding-right: 0;
        .expandable-program-row {
          margin: 16px;
        }
      }

      .expandable-program-row {
        background-color: #F4F5F9;
        > div {
          padding: 0 16px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          .ant-progress {
            //margin-bottom: 11px;
            display: none;
          }
          .title {
            width: 100%;
            margin-top: 16px;
            margin-bottom: 16px;
          }
          > div:last-child {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        > div:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.program-row {
  background-color: #F4F5F9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.programs-inner-wrapper{
  background-color: #e7e8ec;
  padding: 25px;
}

.program-title {
  flex-grow: 1;
  width: 25%;
}