@import 'assets/scss/_variables.scss';

.program-list-wrapper {
  margin: 20px 100px;
  @media (max-width: 600px) {
    margin: 20px 50px;
  }
  @media (max-width: 500px) {
    margin: 20px 15px;
  }
}
.program-list {
  margin-bottom: 74px;
  .ant-card {
    margin-bottom: 16px;
    background: #FFFFFF;
    box-shadow: 0 1px 4px 1px rgba(17, 20, 30, 0.1);
    border-radius: 8px;
    .ant-card-body {
      padding: 16px 24px;
    }
    .program-container {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-template-rows: auto auto auto;
      gap: 0 0;
      grid-template-areas:
    "badge status status"
    "content content content"
    "button button button";
    }
    .button {
      grid-area: button;
      a {
        margin: 0;
      }
      .ant-btn {
        width: 100%;
      }
    }
    .content {
      grid-area: content;
      margin: 16px 0;
      span {
        display: block;
        margin-top: 16px;
        &:first-child {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-top:0;
        }
        i {
          color: $theme-color;
          margin-right: 10px;
        }
        &.deadline {
          color: #FAAD14;
          i {
            font-size: 6px;
            margin-right: 8px;
            line-height: 22px;
            vertical-align: middle;
          }
        }
      }
    }
    .badge {
      grid-area: badge;
    }
    .status {
      justify-self: end;
      align-self: center;
      grid-area: status;
    }
    .ant-tag {
      margin-right: 0;
    }
  }
  @media(min-width: 900px) {
    margin-bottom: 0;
    .ant-card {
      .program-container {
        display: grid;
        grid-template-columns: 61px auto 100px 33px 147px;
        grid-template-rows: auto;
        gap: 0px 16px;
        grid-template-areas:
    "badge content status status button";
      }
      .badge {
        justify-self: start;
        align-self: center;
        grid-area: badge;
      }
      .content {
        justify-self: start;
        align-self: center;
        grid-area: content;
        margin: 0;
        span {
          display: inline-block;
          margin-bottom: 0;
          &:first-child {
            display: block;
          }
          &:not(:first-child),
          &:not(:last-child){
            margin-right: 16px;
          }
        }
      }
      .status {
        align-self: center;
        grid-area: status;
      }
      .button {
        justify-self: end;
        align-self: center;
        grid-area: button;
        width: 147px;
      }
    }
  }
}