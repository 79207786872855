.search-bar-row {
  display: flex;
  flex-wrap: wrap;
  button {
    width: 100%;
  }
  .search {
    margin-top: 16px;
    width: 100%;
    .table-search-field {
      float: none;
      width: 100%;
    }
  }
  @media(min-width: 550px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    .search {
      margin-top: 0;
    }
    button {
      max-width: 200px;
    }
    .search {
      max-width: 264px;
    }
  }
}

.custom-field-table {
  .ant-input-number-group .ant-input-number {
    width: 64px;
  }
  .fa-ellipsis {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-table-cell svg {
    margin-left: 0;
  }
}

.custom-field-popover {
  padding: 4px 0;
  .popover-button {
    padding: 5px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    width: 118px;
    &:hover {
      background: #F5F5F5;
    }
  }
}

.add-custom-field-modal {
  @media(min-width: 550px) {
    .ant-modal-body {
      .ant-form-item-row {
        justify-content: end;
      }
      .ant-col-6 {
        min-width: 300px;
      }
      .ant-form-item-row {
        .ant-form-item-label {
          label {
            justify-content: right;
          }
        }
      }
      .ant-form-item:last-child {
        margin-bottom: 0;
        .ant-form-item-control {
          max-width: 300px;
        }
      }
    }
  }
  .multiple-choice {
    max-width: 300px;
    margin-left: auto;
    .ant-input {
      height: 30px;
    }
    button {
      display: block;
      margin: -19px auto 0 auto;
    }
  }
}

.edit-title-modal {
  .ant-modal-body {
    .ant-col-6 {
      max-width: 400px;
      &:first-child {
        display: none;
      }
    }
  }
}