.audience-notification-header-list {
    background-color: #F4F5F9;
    padding: 0 32px 14px 32px;
    margin-bottom: 16px;
    border: unset;
    border-radius: 8px;

    .ant-list-header {
        padding-right: unset;
        padding-left: unset;
        font-weight: bold;
        border-bottom: unset;
    }
}