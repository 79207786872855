.gap-5 {
  gap: 5px
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px
}

.gap-30 {
  gap: 30px
}

.gap-40 {
  gap: 40px
}

.gap-50 {
  gap: 50px
}




