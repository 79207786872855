@import 'assets/scss/_variables.scss';

.studentDashboard {
  background: $menu-bg-color;
  color: $menu-text-color;
  position:fixed;
  bottom: 0;
  z-index: 2;
  width: 100%;
  .activity-list-wrapper {
    margin: 0;
    display: flex;
    > div {
      flex: 1 0 90px;
      padding: 8px 20px;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      i {
        font-size: 14px;
        display: block;
        margin-bottom: 4px;
      }
      a {
        color: inherit;
      }
      &.active-section {
        background: $menu-active-bg-color;
        color: $menu-active-text-color;
      }
    }
  }

  @media(min-width: 900px) {
    position: static;
    margin-top: 64px;
    box-shadow: 0 1px 4px 1px rgba(17, 20, 30, 0.1);
    .activity-list-wrapper {
      margin: 0 100px;
      display: block;
      > div {
        display: inline-block;
        padding: 12px 20px;
        font-size: 14px;
        line-height: 22px;
        i {
          display: inline-block;
          margin-right: 10px;
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
}