.certificate {
  .ant-form-item:first-child {
    display: none;
  }
  .check-box {
    > .ant-form-item-row {
      flex-direction: row-reverse;
    }
  }
}
.certificate-delete {
  div {
    display: inline;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.85);
  }
  button {
    padding: 4px 15px;
    .fa-trash + span {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 0;
    }
  }
  .fa-trash {
    margin: 0 10px 0 0;
  }
}
.certificates-table {
  .fa-ellipsis {
    color: rgba(0, 0, 0, 0.25);
  }
}
.input-replacement {
  margin-bottom: 24px;
}
.table-filter-modal {
  margin: 16px 0;
}