// Base
$base-border-radius: 8px;
$base-border-color: #F0F0F0;

// Base colors
$base-color: #2F224B;
$base-color-dark: #1F182F;
$base-color-active: #7633B9;
$base-color-separator: #E8E8E8;
$base-color-hover: #F7F7FF;
$base-color-gray-light: #F4F5F9;
$base-color-gray-1: #FAFAFA;

// Theme colors
$theme-color: var(--themeColor, #19A9CE);

// Menu
$menu-text-color: var(--navTextColor, #B7B1BF);
$menu-active-text-color: var(--navActiveTextColor, #FFFFFF);
$menu-bg-color: var(--navBackgroundColor, #2F224B);
$menu-submenu-bg-color: var(--navSubmenuBackgroundColor, #1F182F);
$menu-active-bg-color: var(--navActiveBackgroundColor, #7633B9);


// Buttons
$button-height-big: 42px;

//// Colors
$button-color: #595959;
$button-color-hover: $theme-color;
$button-color-focus: $button-color-hover;

$button-color-primary: #FFFFFF;
$button-color-primary-disabled: rgba(0, 0, 0, 0.25);

$button-color-danger: #FF4D4F;

$button-color-student: $theme-color;
$button-color-student-primary: white;

//// Backgrounds
$button-bg-01: #19A9CE;
$button-bg-02: #00A8CE;
$button-bg-primary: $theme-color;
$button-bg-primary-hover: $theme-color;
$button-bg-primary-focus: $button-bg-primary-hover;
$button-bg-primary-disabled: rgb(245, 245, 245);

//// Misc
$button-padding: 8px;
$button-padding-big: 12px;

$button-border-color-hover: $theme-color;
$button-border-color-focus: $button-border-color-hover;
$button-border-color-primary: $button-bg-primary;
$button-border-color-primary-disabled: rgb(217, 217, 217);
$button-border-color-student: $theme-color;

$button-border-width-student: 2px;

$button-border-radius: $base-border-radius;

$button-font-weight-student: bold;

// Icon colors
$icon-input-color: rgba(0, 0, 0, 0.25);


// Inputs
$input-height: 32px;
$input-width-search: 150px;

$input-padding: 0 12px;
$input-border-hover: $theme-color;
$input-border-focus: $input-border-hover;
$input-border-radius: $base-border-radius;

$input-box-shadow-focus: 0 0 0 2px rgba($input-border-focus, 0.2);


// Text colors
$text-color-01: #262626;
$text-color-02: #595959;
$text-color-03: #8C8C8C;
$text-color-04: #D9DFFF;
$text-color-05: #ffffff;


// Link colors
// TODO: remove references to these colours and move towards semantic variables ex. $link-color-hover instead of $link-color-02
$link-color: $theme-color;
$link-color-hover: $theme-color;
$link-color-focus: $link-color-hover;


// Layout parameters
$header-height: 64px;
$footer-height: 56px;
$sidebar-width: 220px;
$collapsed-sidebar-width: 80px;


$padding-for-fa-ellipses-v: 8px;


// Alerts
$alert-border-radius: $base-border-radius;


// Modals
$modal-border-radius: $base-border-radius;


// Cards
$card-border-radius: $base-border-radius;


// Tabs
$tab-border-radius: $base-border-radius $base-border-radius 0 0;


// Tags
$tag-border-radius: $base-border-radius;
$tag-font-size: 12px;
$tag-font-weight: 600;


// Content Menu
$content-menu-background-color: #FFFFFF;
$content-menu-text-color: #262626;
$content-menu-background-active-color: #7633B9;
$content-menu-active-text-color: #FFFFFF;


// Session calendar
$session-calendar-bg-color: #FFFFFF;
$session-calendar-bg-today: #fff2f0;
