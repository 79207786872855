@import 'assets/scss/_variables.scss';

.learning-test-question {
  .question-title-wrapper {
    display: flex;
    grid-gap: 15px;

    .question-title {
      margin-bottom: 15px;
      width: 100%;
    }
    .ant-image {
      width: 70px;
      height: 70px;
      background: #ccc;

      .thumbnail-preview {
        width: 70px;
        height: 70px;
        object-fit: contain;
        vertical-align: top;
      }
    }
  }
  
  .question-answers {
    margin-top: 15px;
    margin-bottom: 15px;

    .ant-image {
      width: 70px;
      height: 70px;
      background: #ccc;

      .thumbnail-preview {
        width: 70px;
        height: 70px;
        object-fit: contain;
        vertical-align: top;
      }
    }
  }
  label.choices-wrapper > span {
      margin-left: 10px;
  }
  .answer-item {
    margin-bottom: 15px;
    position: relative;

    .ant-checkbox-wrapper,
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      grid-gap: 10px;

      .choices-option-content {
        color: black;
        display: flex;
        align-items: center;
        grid-gap: 10px;
      }
    }

    .correct-answer {
      position: absolute;
      left: -25px;
      top: calc(50% + 3px);
      color: #73D13D;
      font-size: 18px;
      transform: translateY(-50%);
    }
    .points-awarded {
      position: absolute;
      left: -36px;
      top: calc(50% + 3px);
      color: #73D13D;
      font-size: 13px;
      transform: translateY(-50%);

      &.is-incorrect {
        color: red;
      }
    }
  }

  .question-overview-status {
    margin-top: auto;
    button {
      span.question-points {
          height: 20px;
          line-height: 20px;
          background: $base-border-color;
          color: #000;
          border-radius: 30px;
          text-align: center;
          position: absolute;
          right: 5px;
          top: 5px;
          font-size: 12px;
          padding: 0px 10px;
      }
    }
  }
}
.test-attempt-page {
  min-height: calc(100vh - 160px);
  padding: 70px 24px 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .test-attempt-header {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid $base-border-color;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 0px 24px;

    .timer i {
      color: var(--themeColor, #19A9CE);
      margin-right: 10px;
    }
  }
  .test-attempt-footer {
    height: 72px;
    width: 100%;
    border-top: 1px solid $base-border-color;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0px 24px;
  }
  .test-attempt-card {
    padding: 64px;
    min-height: 480px;
    max-width: 800px;
    width: 100%;
    border: 1px solid $base-border-color;
    border-radius: 8px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    &.question-type-HS  {
      padding: 20px;
    }

    &.question-type-MA {
      padding: 30px;
    }

    .spinner-wrapper,
    .ant-spin-container,
    .learning-test-question {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .attempt-complete-card {
    text-align: center;
    h4 {
      margin-bottom: 40px;
    }
  }
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}