.sidebar-questions {
  .question-type {
    border: 2px solid #E8E8E8;
    align-self: center;
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    height: 40px;
    cursor: pointer;
    margin-bottom: 10px;
    &:hover {
      border-color: #19A9CE;
    }
    
    .title {
      width: 100%;
      display: inline-block;
      margin-left: 10px;
    }
  }
}