@import 'assets/scss/variables';
.assessment-submission {
  .separator {
    border-bottom: 1px solid $base-border-color;
    margin: 10px 0px;
  }

  .assessment-instructor-comment-form,
  .assessment-final-result-form {
    .ant-input-textarea {
      padding: initial;
    }
  }

  .assessment-student {
    margin: 10px 0px 20px;
    .user-thumb {
      font-size: 32px;
      color: #BFBFBF;
      margin-right: 15px;
    }
    .user-info {
      h4 {
        font-size: 16px;
        color: #000000D9;
        margin: 0px 0px 0px;
        line-height: 24px;
      }
      h5 {
        font-size: 14px;
        color: #00000073;
        margin: 0px 0px 5px;
        line-height: 22px;
      }
    }
  }

  .assessment-overview {
    .assessment-row {
      border-bottom: 1px solid $base-border-color;
      padding: 10px 0px;
      &.is-submitted {
        .row-icon {
          color: #52C41A;
        }
      }
      .row-icon {
        font-size: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #00000073;
      }
      .row-title {
        width: 50%;
        min-width: 220px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h4 {
          margin-bottom: 0px;
        }
      }
      .row-comments {
        margin-left: 10px;
        h4 {
          margin-bottom: 0px;
        }
      }
      .row-actions {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }

    .assessment-instructor-comment-form {
      .ant-form-item-row {
        flex-direction: column;

        .ant-form-item-label {
          text-align: initial;
        }
      }
    }
  }

  .assessment-description {
    .assessment-documents-list {
      list-style: none;
      padding-left: 0px;
  
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $base-border-color;
      }
    }
  }

  .assessment-files {
    .assignment-section + .assignment-section {
      margin-top: 30px;
    }
    .assignment-title-bar {
        background: $base-color-gray-light;
        border-radius: 8px;
        padding: 10px 15px;
        margin-bottom: 20px;

        .assignment-title {
            grid-gap: 20px;

            h3 {
                margin-bottom: 0px;
            }
        }
        .assignment-subtitle {
            font-size: 12px;
            color: #00000073;
            margin-top: 10px;
        }
        .toggle-submission {
            margin-left: auto;
        }
    }
    .assignment-description {
        font-size: 14px;
        overflow-wrap: break-word;
        color: #00000073;
    }

    .assignment-upload-button {
        text-align: center;
        display: flex;
        grid-gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        &>span:first-child {
            width: 100%;
            max-width: 400px;
        }
        .ant-upload.ant-upload-drag {
            border-radius: 8px;
            padding: 0px 20px;
        }
    }
  }
}
