.my-session-list-item {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 8px;
    justify-content: space-between;
    align-items: center;
    background: white;
    box-shadow: 0px 1px 4px 1px rgba(17, 20, 30, 0.1);
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;

    &--title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
    }

    &--head {
        display: flex;
        align-self: flex-start;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        & > div:first-child {
            align-self: flex-start;
            width: 52px;
            height: 52px;
            background: #F4F5F9;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            padding: 4px 12px 0 12px;
    
            &>span:last-child {
                font-weight: 800;
            }
        }
    }

    &--info {
        display: flex;
        align-self: flex-start;
        flex-direction: column;
        row-gap: 6px;

        &>div:first-child {
            
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
        }

        &>div:last-child {
            color: rgba(0, 0, 0, 0.45);

            span:not(:first-child) {
                &::before {
                    vertical-align: 3px;
                    margin: 0 5px;
                    display: inline-flex;
                    width: 3px;
                    height: 3px;
                    background: rgba(0, 0, 0, 0.45);
                    border-radius: 100px;
                    content: "";
                }
            }
        }
    }

    &--status {
        display: none;
    }

    &--cta {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .my-session-list-item {
        flex-direction: row;

        &--head {
            width: auto;
            & > div:last-child {
                display: none;
            }
        }

        &--status {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }

        &--cta {
            width: 150px;
        }
    }
}
