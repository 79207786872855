@import 'assets/scss/_variables.scss';


.reports-element{
  background-color: #fff;
  padding: 13px 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin-bottom: 24px;
}

.report-separator{
  padding: 5px 24px;
  color: #D9D9D9;
}

.report-tab{
  cursor: pointer;
  padding: 5px 32px;
  color: rgba(72, 79, 86, 0.85);
  i {
    padding-right: 10px;
  }
}


.active-report{
  color: $theme-color;
}