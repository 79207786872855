.ant-picker-time-panel-column {
  overflow-y: auto;
}

.ant-picker-ranges .ant-picker-ok {
  margin-left: 0;

  .ant-btn-sm {
    padding: 0 8px;
  }
}

.ant-picker-time-panel-column {
  width: 135px;
}

.ant-picker-time-panel-cell-inner::after {
  content: ':00'
}

.when-to-send {
  margin: 0;
}

.popover-table {
  .ant-table-container {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .ant-table-pagination.ant-pagination {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.campaign-progress-checkbox {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
}

.search-user-wrapper {
  display: flex;
  gap: 10px;
  width: 60%;

  div {
    width: 100%;
  }
}

.selected-users-box {
  width: 100%;
  height: 150px;
  overflow-y: scroll;
  border: 1px solid #e6e6e6;
}

.selected-users-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;

  .fa-regular {
    cursor: pointer;
  }
}