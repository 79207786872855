@import 'assets/scss/_variables.scss';

.material-container {
  max-width: 1700px;
  margin: auto;

  @media (max-width: 1725px) {
    margin: 0 50px;
  }
  @media (max-width: 600px) {
    margin: 0 15px;
  }
}

.material-breadcrumb {
    margin-top: 80px;
    @media(min-width: 900px) {
      margin-top: 24px;
    }
}

.student-program-title {
  margin-bottom: 0;
  .arrow {
    color: #000000;
    padding-right: 16px;
  }
  h1 {
    margin-bottom: 0;
  }
}

.program-details {
  margin-bottom: 8px;

  .fa-bars,
  .fa-clock,
  .fa-flag-checkered {
    padding-right: 4px;
    color: #19A9CE;
    @media (min-width: 500px) {
      padding-right: 10px;
    }
  }

  > div:first-child {
    > span:first-child {
      margin-right: 16px;
    }
  }

  > div:nth-child(2) {
    > span:first-child {
      margin-right: 8px;
    }
  }

  span {
    white-space: nowrap;
  }

  .ant-progress-steps-item {
    box-shadow: 0 1px 4px 1px rgba(17, 20, 30, 0.1);

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .ant-tag {
    margin-right: 0;
  }
}

.card {
  box-shadow: 0 1px 4px 1px rgba(17, 20, 30, 0.1);
  margin-bottom: 15px;
  @media (min-width: 900px) {
    &:not(:nth-of-type(2)) {
      .student-material-title {
        display: none;
      }
    }
  }

  &:last-child {
    margin-bottom: 128px;
  }
}

.program-data-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.student-material {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  @media (max-width: 900px) {
    flex-direction: row;
    justify-content: flex-start;

    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}

.student-material-title {
  font-weight: 600;
  height: 100%;

  @media (max-width: 900px) {
    width: 45%;
  }
}

.lecture-card {
  @media (max-width: 900px) {
    .time-spent,
    .student-result,
    .pass-rate,
    .action {
      display: none;
    }
  }
}

.title {
  width: 25%;
}

.time-spent,
.student-result,
.pass-rate,
.student-status,
.mandatory {
  width: 5%;
}

.action {
  width: 20%;
  padding: 24px;
  justify-content: flex-end;
}

@media (max-width: 900px) {
  .title,
  .time-spent,
  .student-result,
  .pass-rate,
  .student-status,
  .mandatory,
  .action {
    width: 100%;
    padding: 16px 24px;
  }

  .mobile-hide{
    display: none;
  }
}

.student-material-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-direction: column;
}

.child {
  background-color: transparent;
  height: 100%;
}

.activity-card-body {
  .ant-card-body{
    padding: 0 24px;
  }
}

