@import 'assets/scss/_variables.scss';

.scorm-player {
  display: block;
  width: 80vw; height: 86.5vh;
  margin: 0 auto;
  border: none;
}

.embedded-player{
  height: 76.5vh;
  width: 100%;
  margin: 0;
  border: none;
}