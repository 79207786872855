@import '~antd/dist/antd.css';
@import '../fonts/gilroy/stylesheet.css';
@import '../fonts/DINPro/stylesheet.css';
@import '../fonts/TT/stylesheet.css';
@import './variables';
@import './utils';
@import './mixins';
@import './height';
@import './flex';
@import './margins';
@import './paddings';
@import './width';
@import './gaps';
@import './font';
@import './background';

// Components
@import './components/alerts';
@import './components/buttons';
@import './components/inputs';
@import './components/links';
@import './components/menu';
@import './components/modals';
@import './components/cards';
@import './components/tabs';
@import './components/tags';
@import './components/switch';
@import './components/inputNumber';
@import './components/checkbox';
@import './components/radioGroup';
@import './components/pagination';
@import './components/steps';

body {
  margin: 0;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// TODO: when there will be select component bring this out
.ant-select .ant-select-selector {
  border-radius: 8px;
}



.fullscreen-spinner {
  width: 100vw; height: 100vh;

  @include flex-center;
}

// Experimental fix for scroll bar flicker on certain resolutions
.ant-table-expanded-row-fixed { width: 100% !important; }

.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pointer{
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.important-x-cursor{
  cursor: not-allowed !important;
}

.base-border-radius{
  border-radius: $base-border-radius;
}

.hidden {
  display: none;
}

.disabled-cell{
  color: darkgrey;
  a {
    color: darkgrey;
  }
}
.red{
  color: red;
}

.theme-color {
  color: $theme-color;
}