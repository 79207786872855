@import 'assets/scss/variables';

.grading-detail-section + .grading-detail-section {
    margin-top: 30px;

    .grading-detail-title-bar {
        background: $base-color-gray-light;
        border-radius: 8px;
        padding: 10px 15px;
        margin-bottom: 20px;
    
        .grading-detail-title {
            grid-gap: 20px;
    
            h3 {
                margin-bottom: 0px;
            }
        }
        .grading-detail-subtitle {
            font-size: 12px;
            color: #00000073;
            margin-top: 10px;
        }
        .toggle-submission {
            margin-left: auto;
        }
    }
}