.p-0 {
  padding: 0;
};
.px-0 {
  padding-left: 0;
  padding-right: 0;
};
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
};
.pt-0 {
  padding-top: 0;
};
.pr-0 {
  padding-right: 0;
};
.pb-0 {
  padding-bottom: 0;
};
.pl-0 {
  padding-left: 0;
};

.p-1 {
  padding: 0.25rem;
};
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
};
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
};
.pt-1 {
  padding-top: 0.25rem;
};
.pr-1 {
  padding-right: 0.25rem;
};
.pb-1 {
  padding-bottom: 0.25rem;
};
.pl-1 {
  padding-left: 0.25rem;
};

.pb-1 {
  padding-bottom: 2px;
};

.p-2 {
  padding: 0.5rem;
};

.p-3 {
  padding: 0.75rem;
};

.p-4 {
  padding: 1rem;
};


.p-5 {
  padding: 1.25rem;
};
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
};
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
};
.pt-5 {
  padding-top: 1.25rem;
};
.pr-5 {
  padding-right: 1.25rem;
};
.pb-5 {
  padding-bottom: 1.25rem;
};
.pl-5 {
  padding-left: 1.25rem;
};

.p-6 {
  padding: 1.5rem;
};


.pb-10 {
  padding-bottom: 10px;
};

.pt-64px {
  padding-top: 64px;
};



.py-16px {
  padding-top: 16px;
  padding-bottom: 16px;
};