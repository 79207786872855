.student-result-wrapper {
  border-bottom: lightgray 1px solid;
  padding: 16px;

  &:hover {
    background-color: #d9d9d9;
  }
}

.program-student-table {
  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    padding: 0;
  }
}


.student-response-wrapper {
  background-color: #0000000F;
  border-bottom: 2px solid #e6e7ea;
}

.full-width-select{
  .ant-select {
    width: 100%;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  align-items: center;
}