@font-face {
    font-family: 'DINPro';
    src: url('DINPro-Regular_13937.ttf');
    font-weight: 300;
}

@font-face {
    font-family:'DINPro';
    src: url('DINPro Bold.otf');
    font-weight: 600;
}