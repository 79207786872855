.search-bar-row {
  display: flex;
  flex-wrap: wrap;

  a {
    width: 100%;
  }

  .search {
    margin-top: 16px;
    width: 100%;

    .table-search-field {
      float: none;
      width: 100%;
    }
  }

  @media(min-width: 600px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    .search {
      margin-top: 0;
      margin-left: 16px;
    }
    a {
      max-width: 200px;
    }
    .search {
      max-width: 264px;
    }
  }
}

.remove-flex-grow {
  .ant-col.ant-form-item-control {
    flex-grow: 0;
  }
}

.table-row-red {
  td {
    background: #ffcccc !important;
  }
}

.table-row-red:hover {
  td {
    background: #ffa8a8 !important;
  }
}