@import 'assets/scss/_variables.scss';

.page-header {
  .ant-breadcrumb {
    margin: 0;
    padding: 16px 0;
    font-weight: 500;
    color: $text-color-03;
  }

  h1 {
    i {
      font-size: 16px;
      color: $text-color-01;
      padding-right: 10px;
    }

    font-size: 32px;
    margin-bottom: 24px;
    line-height: 1;
  }

  &.loading {
    visibility: hidden;
  }
}
.page-layout {
  &.with-sidebar {
    .page-header {
      h1 {
        font-size: 22px;

        button {
          padding: 2px;
          height: initial;
          vertical-align: top;
          float: right;
          i {
            color: $theme-color;
          }
        }
      }
      .ant-breadcrumb li:first-child a {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}