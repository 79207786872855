@import 'assets/scss/_variables.scss';
.settings-panel {
  background: #F4F5F9;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
  &>.ant-row {
    border-bottom: 1px solid #D9D9D9;
    padding: 8px 0px;

    &:last-child {
      border-bottom: none;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
}