
.phone-select-wrapper {
    .ant-form-item{
        margin-bottom: -2px;
    }

    .ant-input-group-addon{
        padding: 0;
    }

    .ant-select-selection-item{
        width: 100px;
    }

    .ant-input-group-addon{
        border-radius: 8px 0 0 8px;
    }
}