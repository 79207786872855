@import 'assets/scss/variables';

.my-session-calendar-title {
    padding-top: 12px;
    padding-bottom: 12px;
}

.my-session-calendar {
    background: $session-calendar-bg-color;
    box-shadow: 0px 1px 4px 1px rgba(17, 20, 30, 0.1);
    border-radius: 8px;
    padding: 32px;

    .fc {
        .fc-header-toolbar {
            padding-bottom: 1em;
            border-bottom: 1px solid rgba(17, 20, 30, 0.1);

            .fc-toolbar-title {
                color: $base-color;
                font-size: 1.25em;
                font-weight: 600;
            }

            button {
                &.fc-prev-button,
                &.fc-next-button {
                    background-color: $session-calendar-bg-color;
                    border-color: $session-calendar-bg-color;
                    color: $base-color;
                }
            }
        }

        a {
            &.fc-col-header-cell-cushion,
            &.fc-daygrid-day-number {
                cursor: default;
                color: $base-color;
            }
        }
        
        .fc-day-today {
            background-color: $session-calendar-bg-today;
        }
    }
}