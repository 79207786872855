
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.flex-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.flex-column {
  flex-direction: column;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-start {
  justify-content: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-5 {
  gap: 5px;
}
