.multiple-choice-fields {
  .ant-checkbox-wrapper {
    width: 100%;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
.word-break {
  white-space: break-spaces;
  word-break: break-word;
}