@import 'assets/scss/_variables.scss';

$theme-color-global: var(--themeColorGlobal, #19A9CE);

.localeSwitch {
  border-radius: 8px;
  background: transparent;
  color: #FFFFFF;
  font-size: 12px;

  &:hover,
  &:active,
  &:focus {
    color: #FFFFFF;
    font-weight: 600;
    background: transparent;
    border-color: $theme-color-global;
  }
}


.localeSwitchActive {
  color: #FFFFFF;
  font-weight: 600;
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    color: #FFFFFF;
    font-weight: 600;
    background: transparent;
    border-color: $theme-color-global;
  }
}