@import 'assets/scss/_variables.scss';
.fa-ellipsis-v{
    padding:$padding-for-fa-ellipses-v;
}
.delete-success-modal-content{
    display: flex;
    flex-direction: column;
}
.user-roles {
  span:nth-child(2) {
      margin-top: 10px;
  }
}