
.h-0 {
  height: 0;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-12 {
  height: 3rem;
}

.h-13 {
  height: 3.25rem;
}

.h-14 {
  height: 3.5rem;
}


.h-20 {
  height: 15rem;
}

.h-100pr {
  height: 100%;
}

.h-95vh {
  height: 95vh;
}

.h-34 {
  height: 34px;
}

.h-80{
  height: 80px;
}