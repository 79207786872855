@import 'assets/scss/variables';

.info-table {
  border: 1px solid $base-border-color;
  .info-table-row:not(:last-child) {
    border-bottom: 1px solid $base-border-color;
  }
  .info-table-row {
    display: flex;

    &:nth-child(even) {
      background: $base-color-gray-1;
    }

    .info-table-col {
      padding: 15px;
      flex: 1;

      &.label-col {
        border-right: 1px solid $base-border-color;
        width: 20%;
        flex: 0 0 20%;
      }
    }
  }
}