


.w-240 {
  width: 240px;
}
.w-250 {
  width: 250px;
}

.min-w-320 {
  min-width: 320px;
}

.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}

.w-100 {
  width: 100%;
}

.cell-w-250 {
  width: 250px;
}
