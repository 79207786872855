.no-bottom-margin {
  .ant-form-item{
    margin-bottom: 0;
    width: 400px;
  }
}

.sort-table-row{

}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.program-activities {
  h1 {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(17, 20, 30, 0.1);
  }

  .activity-sum {
    margin-top: 16px;
    margin-bottom: 16px;
    .ant-space-item {
      span {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 22px;
        div {
          color: rgba(0, 0, 0, 0.85);
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  .activity-name {
    > div {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .strict-order {
    margin-top: 16px;
  }
}
