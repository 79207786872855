.default-input-field {
  padding: 0 12px;
  border-radius: 8px;
  height: 32px;
}
.ant-input-search {
  .ant-input-group {
    .ant-input-affix-wrapper:not(:last-child) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .ant-input-search-button {
    height: 32px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &> .ant-input-group {
    &> .ant-input-group-addon:last-child {
      .ant-input-search-button {
        border-radius: 0 8px 8px 0;
      }
    }
  }
}
