.my-session-details {
    .ant-btn-default {
        border-color: var(--themeColor, #19A9CE);
    }

    &--info {
        margin-top: 16px;

        >div {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            margin: 8px 0;

            >div:nth-of-type(1) {
                width: 16px;

                i {
                    color: var(--themeColor, #19A9CE);
                }
            }

            >div:nth-of-type(2) {
                padding: 0 8px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            >div:nth-of-type(3) {
                i {
                    color: var(--themeColor, #19A9CE);
                }
            }
        }

        >div:nth-of-type(1) {
            span:not(:first-child) {
                &::before {
                    vertical-align: 3px;
                    margin: 0 5px;
                    display: inline-flex;
                    width: 3px;
                    height: 3px;
                    background: rgba(0, 0, 0, 0.45);
                    border-radius: 100px;
                    content: "";
                }
            }
        }

    }


    &--description {
        padding: 16px 0;
        margin: 10px 0;
        border-top: 1px solid #F0F0F0;
        border-bottom: 1px solid #F0F0F0;
    }

    &--file {
        >div {
            width: 100%;
        }

        margin: 16px 0;
        display: flex;
        padding: 10px;
        border: 1px solid #F0F0F0;
        border-radius: 8px;

        &-item {
            padding: 8px 24px;
            box-shadow: inset 0px -1px 0px #F0F0F0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >i {
                font-weight: 300;
            }

            >span.ant-typography {
                justify-self: flex-start;
                flex: 1;
                margin-left: 12px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

@media (max-width:500px) {
    .my-session-details {
        .ant-btn-default {
            width: 100%;
        }

        &--file-item {
            padding: 8px 0;
        }
    }
}