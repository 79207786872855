@import 'assets/scss/_variables.scss';
.crop-container {
  position: relative;
  width: 100%;
  height: 600px;
  background: #333;
  @media (min-width: 600px) {
    height: 400px;
  }
}

.controls {
  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 5px;
    border: 1px solid $base-color-active;
    border-radius: 8px;
    width: 48%;
    accent-color: $theme-color;
  }
}

