.spinner-wrapper.opaque {
  background: #fff;

  .ant-spin-blur {
    opacity: 0;
  }
}

.spinner-wrapper.opaqueH {
  background: #fff;

  .ant-spin-blur {
    opacity: 0.5;
  }
}

.spinner-wrapper.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}