@import 'assets/scss/_variables.scss';

.activity-list-wrapper {
  margin-top: 56px;
  .filter-container {
    .search {
      .table-search-field {
        margin: 16px 0 15px 0;
        float:none;
        width: 100%;
      }
    }
    .filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        cursor: pointer;
        &:not(:last-child) {
          margin-right:16px;
        }
      }
      .filter-switch {
        padding: 5px 0;
        i.fa-chevron-down {
          margin-left: 4px;
        }
      }
    }
  }
  .filter-clear {
    display: block;
    margin-bottom: 16px;
    padding: 5px 0;
    color: $theme-color;
    cursor: pointer;
  }

  @media(min-width: 900px) {
    margin: 0 100px;
    .filter-container {
      display: flex;
      justify-content: space-between;
      .search {
        order: 2;
        .table-search-field {
          margin: 32px 0 0 0;
          min-width: 264px;
        }
      }
      .filters {
        margin-top: 32px;
        flex-wrap: nowrap;
        .filter-switch {
          i.fa-chevron-down {
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.ant-dropdown-menu {
  padding: 0;
}