@import 'assets/scss/_variables.scss';

.answer-item-ma {
  .ma-answer-row {
    display: flex;
    grid-gap: 20px;
    position: relative;
    border-top: 1px solid $base-border-color;
    padding: 10px 0px;
    align-items: center;
    &:last-child {
      border-bottom: 1px solid $base-border-color;
    }

    .correct-answer {
      position: absolute;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
      color: #73D13D;
      font-size: 18px;
    }

    span.ma-answer-number {
      background: #F4F5F9;
      border: 1px solid #F0F0F0;
      border-radius: 30px;
      width: 20px;
      height: 20px;
      line-height: 18px;
      flex-shrink: 0;
      text-align: center;
      font-size: 11px;
      font-weight: bold;
    }
  }
  .ma-static-item-wrapper {
      position: relative;
      width: 50%;
      display: flex;
      align-items: center;
      grid-gap: 10px;
      justify-content: end;

      .ma-answer-item {
        max-width: initial;
      }
  }
  .ma-answer-item {
      padding: 5px;
      border: 1px solid #D9D9D9;
      border-radius: 8px;
      position: relative;
      max-width: 50%;
      display: flex;
      align-items: center;
      grid-gap: 10px;

      .ma-answer-text {
        border: 1px solid $base-border-color;
        padding: 8px;
        border-radius: 5px;
        background: $base-color-gray-light;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        span {
          width: 100%;
        }
      }

      .ant-image {
        margin-left: auto;
      }
  }
  .ma-sortable {
    position: relative;
    .ma-sortable-item {
      margin-left: 20px;
      .drag-handle {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        color: #19A9CE;
      }
    }
  }
}
