@import 'assets/scss/_variables.scss';
.learning-test-landing-card {
  padding: 64px;
  min-height: 480px;
  max-width: 800px;
  width: 100%;
  border: 1px solid $base-border-color;
  border-radius: 8px;
  flex-shrink: 0;
  background: #fff;
  text-align: center;
  .color-1 {
    color: #00000073;
  }
  &> h4 {
    margin-bottom: 50px;
  }
  &> h1 {
    margin-bottom: 50px;
    font-size: 2rem;
  }
  .test-stats {
    margin-bottom: 50px;
    label {
      margin-bottom: 15px;
      display: block;
    }
  }
}