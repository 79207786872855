.ant-transfer-list {
  border-radius: 8px;
  .ant-transfer-list-header {
    border-radius: 8px 8px 0px 0px;
  }
  .ant-transfer-list-header-selected {
    display: none;
  }
  .ant-transfer-list-header-title {
    text-align: left;
  }
}