@import 'assets/scss/variables';

.assignment-form-wrapper {
    margin-left: 100px;
    margin-right: 100px;
    @media (max-width: 600px) {
      margin: 0 50px;
    }
    @media (max-width: 500px) {
      margin: 0 15px;
    }
    @media(min-width: 900px) {
      margin-top: 0;
    }
  
    .breadcrumb {
      margin-top: 80px;
      margin-bottom: 8px;
      @media(min-width: 900px) {
        margin-top: 24px;
      }
    }
    .title {
      margin-bottom: 24px;
      .arrow {
        color: #000000;
        padding-right: 16px;
      }
      h1 {
        margin-bottom: 0;
      }
    }
    .ant-card {
      margin-bottom: 16px;
      background: #FFFFFF;
      box-shadow: 0 1px 4px 1px rgba(17, 20, 30, 0.1);
      border-radius: 8px;
      .ant-card-body {
        padding: 16px 24px;
      }
    }

    .assignment-card {
        padding: 20px;

        .assignment-section + .assignment-section {
            margin-top: 30px;
        }
        .assignment-title-bar {
            background: $base-color-gray-light;
            border-radius: 8px;
            padding: 10px 15px;
            margin-bottom: 20px;

            .assignment-title {
                grid-gap: 20px;

                h3 {
                    margin-bottom: 0px;
                }
            }
            .assignment-subtitle {
                font-size: 12px;
                color: #00000073;
                margin-top: 10px;
            }
            .toggle-submission {
                margin-left: auto;
            }
        }
        .assignment-description {
            font-size: 14px;
            overflow-wrap: break-word;
            color: #00000073;
        }
    }

    .assignment-upload-button {
        text-align: center;
        display: flex;
        grid-gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        &>span:first-child {
            width: 100%;
            max-width: 400px;
        }
        .ant-upload.ant-upload-drag {
            border-radius: 8px;
            padding: 0px 20px;
        }
    }
  }
