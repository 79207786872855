.transferer-style{
    .ant-transfer-list{
        width:100%;
        height: 100%;
    }

    .ant-transfer-list-content-item-text{
        overflow: auto;
        white-space: break-spaces;
    }
}