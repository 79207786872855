@import 'assets/scss/variables';

.general-messaging-form {
  .form-section {
    &>h2 + hr {
      margin-bottom: 30px;
    }
    hr {
      border: none;
      border-top:1px solid #D9D9D9;
      margin: 20px 0px;
    }
    .form-section-content {
      max-width: 856px;
    }
  }
  .org-box {
    background: #F5F5F5;
    &>.ant-row {
      padding: 15px;
      border-bottom: 1px solid #D9D9D9;
      &:first-child,
      &:last-child {
        border-bottom: none;
      }
    }
    .ant-form-item {
      margin-bottom: 0px;
    }
    .label-row {
      text-transform: uppercase;
    }
  }
  .custom-switch {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000;
    }
  }
}