.title-with-line {
    display: flex;
    margin: 10px 0;

    span {
        white-space: nowrap;
        margin-right: 16px;
        
    }

    div {
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        margin-top: 10px;
        flex: 1;
    }
}