@import 'assets/scss/_variables.scss';
.question-panel {
  .panel-header {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 1px rgba(17, 20, 30, 0.1);
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &> div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 15px;
      .ant-form-item {
          margin-bottom: 0px;
        }
        .panel-header-item {
          display: inline-flex;
          align-items: center;
          &.separator {
            border-left: 1px solid #D9D9D9;
            margin-left: 20px;
          }
          &> label {
            margin-right: 10px;
          }
          &> span {
            color: #00000073;
          }
        }
    }
    
  }
  .panel-body {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 1px rgba(17, 20, 30, 0.1);
    margin-top: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 178px);
    grid-gap: 15px;

    .question-title {
      background: $base-color-gray-light;
      border: 1px solid $base-border-color;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 16px;
    }
    .question-save-buttons {
      display: flex;
      align-items: center;
      justify-content: end;
      grid-gap: 10px;
      margin: 15px 0px 0px;
      margin-top: auto;
    }
  }
  &:not(.question-HS) {
    .question-answers {
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid $base-border-color;
    }
  }
  .question-answers {
    .answers-header > .ant-col,
    .answers-body > div > .ant-row > .ant-col {
      padding: 16px;
      border-right: 1px solid $base-border-color;
      border-bottom: 1px solid $base-border-color;
      border-collapse: collapse;
    }
    .answers-header {
      background: $base-color-gray-light;
    }
  }
  .hotspot-upload-area {
    border-radius: 8px;
    padding: 20px;
    background: $base-color-gray-light;
    border: 1px solid $base-border-color;
    text-align: center;

    .ant-upload.ant-upload-drag {
      background: #FAFAFA;
      border: 1px solid #D9D9D9;
      border-radius: 8px;
      margin: 20px auto 0px;
      max-width: 395px;
    }
  }
  .hotspot-answers-box,
  .hotspot-image-box,
  .edit-hotspot-box {
    border: 1px solid $base-border-color;
    background: $base-color-gray-light;
    border-radius: 8px;
  }
  .hotspot-image-box {
    .hotspot-box-body {
      padding: 10px;

      img {
        width: 100%;
      }
    }
  }
  .hotspot-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 10px 0px 10px;

    .ant-btn {
      background: #fff;
    }
  }
}
.question-panel {
  .question-type {
    &> span {
      margin-right: 5px;
    }
  }
}
.question-pool-uploader {
  min-width: 60px;
  .thumbnail-preview {
    background: #ccc;
    border-radius: 3px;
    border: 1px solid $base-border-color;
  }
  .image-upload-prreview {
    padding: 3px;
    border-radius: 3px;
    border: 1px solid $base-border-color;
  }
  .ant-upload.ant-upload-select-picture-card {
    border: none;
    background-color: initial;
    width: auto;
    height: 30px;
    margin-bottom: 0px;
  }
  .ant-upload-list-picture-card-container {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
    .ant-upload-list-item {
      padding: 3px;
    }

    & + .ant-upload.ant-upload-select-picture-card {
      display: none;
    }
  }
}
.questions-list {
  max-height: calc(100vh - 460px);
  min-height: 290px;
  overflow: auto;
  margin-left: -16px;
  margin-right: -16px;
  border-top: 1px solid $base-border-color;

  .question-item {
      position: relative;
      cursor: pointer;
      padding: 0px 16px;
      border-bottom: 1px solid $base-border-color;
      h4, p {
        margin-bottom: 0px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      p {
        color: #00000073;
      }

      &.active {
        background: $base-color-gray-light;
      }
      .ant-form-item {
          position: absolute;
          left: 16px;
          top: 15px;
      }
      .question-item-info {
        width: 100%;
        padding: 16px 0px;
        padding-left: 30px;
      }
  }
}
.question-list-wrapper {
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #c1c1c12e;
  }
  ::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 10px;

  }
  ::-webkit-scrollbar-thumb:hover {
    background: #C1C1C1;
  }
}
.question-actions {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
}

.edit-hotspot-box {
  padding: 10px;
  border: 1px solid $base-border-color;
    background: $base-color-gray-light;
    border-radius: 8px;

    img {
      max-width: 100%;
    }
}
.hostspot-answer-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-top: 1px solid $base-border-color;
    background: #fff;
  
    &:last-child {
      border-radius: 0px 0px 8px 8px;
    }
    &:hover {
      background-color: #FFE7BA;
    }

    h4 {
      margin: 0px;
    }
    .remove-answer {
      cursor: pointer;
      display: block;
      padding: 5px;
    }
  }
}
.question-edit-page {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;

  &>.spinner-wrapper,
  &>.spinner-wrapper>.ant-spin-container,
  &>.spinner-wrapper>.ant-spin-container>.ant-form,
  &>.spinner-wrapper>.ant-spin-container>.ant-form>.question-panel {
    min-height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}