.my-certificates {
  .heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0;
  }
  .download {
    button {
      width: 100%;
    }
  }
  .date {
    span {
      display: block;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .tags {
    margin: 16px 0;
  }
  @media(min-width: 900px) {
    .container {
      margin: 0;
      display: grid;
      grid-template-columns: 50% 25% 25%;
      grid-template-rows: auto;
      gap: 0 0;
      grid-auto-flow: row;
      grid-template-areas:
    "details tags download";
    }

    .details {
      grid-area: details;
      display: grid;
      grid-template-columns: 60px auto;
      grid-template-rows: auto auto;
      gap: 0 16px;
      grid-auto-flow: row;
      grid-template-areas:
    "badge heading"
    "badge date";
    }

    .badge {
      justify-self: start;
      align-self: center;
      grid-area: badge;
    }

    .heading {
      justify-self: start;
      grid-area: heading;
      margin: 0;
    }

    .date {
      justify-self: start;
      grid-area: date;
      span {
        display: inline-block;
        font-size: 13px;
        &:first-child {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }
    }

    .tags {
      justify-self: center;
      align-self: center;
      grid-area: tags;
      margin: 0;
    }

    .download {
      justify-self: end;
      align-self: center;
      grid-area: download;
      button {
        width: 147px;
      }
    }
  }
}