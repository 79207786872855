@font-face {
    font-family: 'TypeType';
    src: url('TypeType - TT Backwards Sans Black.otf');
    font-weight: 800;
}

@font-face {
    font-family: 'TypeType';
    src: url('TypeType - TT Backwards Sans Bold.otf');
    font-weight: bold;
}

@font-face {
    font-family: 'TypeType';
    src: url('TypeType - TT Backwards Sans Light.otf');
    font-weight: 300;
}

@font-face {
    font-family: 'TypeType';
    src: url('TypeType - TT Backwards Sans Regular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'TypeType';
    src: url('TypeType - TT Backwards Sans Thin.otf');
    font-weight: 100;
}

