.audience-form-items-list {
    background-color: #F4F5F9;
    padding: 0 32px 4px 32px;
    margin-bottom: 16px;
    border: unset;
    border-radius: 8px;

    .ant-form-item {
        margin: unset;

        &:not(:last-child) {
            border-bottom: 1px solid #D9D9D9;
        }

        &:last-child {
            margin-bottom: 8px;
        }

        .ant-form-item-label {
            display: flex;
        }

        &-control-input-content {
            display: flex;
            justify-content: flex-end;
        }
    }

    .ant-form-item-label {
        align-items: center;
    }

    .ant-list-header {
        padding-right: unset;
        padding-left: unset;
        font-weight: bold;
        border-bottom: 1px solid #D9D9D9;
    }

    input[type="text"] {
        margin: 4px 0;
    }

    .audience-rule-set {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D9D9D9;
        padding-top: 5px;

        &:nth-last-child(2) {
            border-bottom: unset;
        }

        &:nth-child(3) {
            margin-top: 12px;
        }

        .audience-rule-set-item {
            .ant-space-item {
                min-width: 100px;

            }

            .ant-space-item:first-child, .ant-space-item:last-child {
                min-width: 240px;
            }
        }
    }
}

.audience-competeces-transfer {
    .ant-transfer-list {
        min-height: 340px;
        flex: 1;
        border: 1px solid #D9D9D9;
        border-radius: 6px;
    }
}

.audience-hyperlink{
    .ant-input-group.ant-input-group-compact {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .ant-form-item.vertical-form-item {
            border-bottom: none;
        }
    }
}