.table-with-expandable-rows-for-programs {
  .ant-table-expanded-row {
    .ant-table-cell {
      padding: 0;
    }

    .expandable-program-row {
      > div {
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        .ant-progress {
          display: none;
          //margin-bottom: 11px;
        }
        .title {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        > div:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      > div:last-child {
        border-bottom: none;
      }
    }
  }
}


.expandable-title-wrapper{
  flex: 70% 0;
}