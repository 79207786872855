.ant-input-number {
    height: $input-height;
    border-radius: $input-border-radius;
    &:focus {
        border-color: $theme-color !important;
    }
    &:hover {
        border-color: $theme-color;
    }
    &-affix-wrapper { padding: $input-padding; }
    .ant-input-number-handler-wrap {
        border-radius: 8px;
    }
}