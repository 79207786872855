@import 'assets/scss/_variables.scss';

.checkbox-wrapper {
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $theme-color;
    border-radius: 4px;
  }
}

.attempts-wrapper{
  .ant-input-number{
    width: 100%;
  }
}

.attempts-popover{
  min-width: 300px;

}

.scrollable-div {
  overflow-y: scroll;

  min-height: 100px;
  max-height: 300px;
}


.report-filter-tag{
  height: 28px;
  padding: 8px 4px;
  background-color: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 6px;

  .fa-xmark{
    cursor: pointer;
  }
}

