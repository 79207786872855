.dragger-style {
  .ant-upload.ant-upload-drag .ant-upload-btn,
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display:block;
  }
  .ant-upload.ant-upload-drag {
    background-color: white;
    border: none;
    padding: 0;
    margin: 0;
    cursor: initial;

    div {
      div:first-child {
        text-align: left;
      }

      div:nth-child(2) {
        border: 1px dashed #DCDCDC;
        padding: 40px;
        cursor: pointer;
        background-color: #fafafa;

        .fa-file-upload {
          font-size: 50px;
          color: #D9D9D9;
          padding: 10px;
        }
      }
    }
  }
}