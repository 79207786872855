.container {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.header {
    width: 100%;
    grid-area: header;
}

.content {
    width: 100%;
    grid-area: content;
}

.languages {
    width: 100%;
    grid-area: languages;
}

.footer {
    width: 100%;
    grid-area: footer;

    &List {
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        color: #fff;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        opacity: 50%;
    }
}

.pointer {
    cursor: pointer;
}

.rixtc {
    background-color: #5B9FFf;
}

.pulsec {
    background-color: #171717;
}

.default {
    background-color: #463368
}

.languageButtons {
    display: flex;
    gap: 5px;
}