@import 'assets/scss/_variables.scss';

$theme-color-global: var(--themeColorGlobal, #19A9CE);


.card {
  max-width: 600px;
  max-height: 410px;
  width: 100%;
}

.center {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.wideButton {
  background-color: $theme-color-global;
  border-radius: 8px;
  border: none;
  height: 42px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: $theme-color-global;
  }
}

.inputField {
  height: 42px;
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-self: center;
  gap: 10px;
}

.logoContainer{
  padding-bottom: 10px;
}