@import 'assets/scss/_variables.scss';

.answer-item-hs {
  .hotspot-image {
      padding: 10px;
      border: 1px solid $base-border-color;
      background: $base-color-gray-light;
      border-radius: 8px;
  }
}
