.manual-player{
  width:100%;
  height: 70vh;
  border: none;
}

.pdf-player{
  height: 168vh;
  border: none;
}

.pdf-player-land{
  height: 81vh;
  border: none;
}