.f-8{
  font-size: 8px;
}

.f-10{
  font-size: 10px;
}

.f-12{
  font-size: 12px;
}

.f-14{
  font-size: 14px;
}

.f-16{
  font-size: 16px;
}

.f-18{
  font-size: 18px;
}

.f-20{
  font-size: 20px;
}

.f-22{
  font-size: 22px;
}

.f-24{
  font-size: 24px;
}

.f-26{
  font-size: 26px;
}

.f-28{
  font-size: 28px;
}

.f-30{
  font-size: 30px;
}

.f-32{
  font-size: 32px;
}

.f-48{
  font-size: 48px;
}

.bold {
  font-weight: 600;
}

.bold-e {
  font-weight: 700;
}

.f-left{
  text-align: left;
}

.f-right{
  text-align: right;
}

.f-start{
  text-align: start;
}

.f-end{
  text-align: end;
}

.f-center{
  text-align: center;
}

.f-up{
  text-transform: uppercase;
}


.c-red{
  color: red;
}

.c-gray {
  color: #8c8c8c;
}