@import 'assets/scss/_variables.scss';


.konva-wrapper{
  border: 1px solid red;
}


.hyperlink-tag {
  color: $base-color-active;
}