@import 'assets/scss/_variables.scss';

$theme-color-global: var(--themeColorGlobal, #19A9CE);

.card {
  padding: 1.5rem;
  max-width: 600px;
  max-height: 410px;
  width: 100%;
}

.center {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.loginButton {
  background-color: $theme-color-global;
  border-radius: 8px;
  border: none;
  height: 42px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: $theme-color-global;
  }
}

.loginText {
  color: $base-color-dark;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 16px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.iconColor {
  color: #000;
  opacity: .25;
}

.forgotPasswordText {
  font-size: 12px;
  color: $link-color;
  text-decoration: underline;
  font-weight: 500;
}

.loginOptionsWrapper {
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.inputField {
  height: 42px;
  display: flex;
  align-items: center;
}

.phoneInputField {
  display: flex;
  align-items: center;
  input {
    height: 40px;
  }
}

.logoContainer{
  padding-bottom: 10px;
}