@import 'assets/scss/_variables.scss';
.edit-hotspot-shape {
    width: 300px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    padding: 10px;
    border: 1px solid $base-border-color;
    background: $base-color-gray-light;
    border-radius: 8px;

    .hide-hotspot-box {
      position: absolute;
      right: 10px;
      top: 5px;
      cursor: pointer;
    }
}

.hotspot-image {
  overflow: hidden;
  position: relative;
}