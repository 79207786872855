.tag-input-wrapper {
  display: flex;
  gap: 10px;
}


.tags-form-wrapper {
  padding: 5px;
  display: flex;
  gap: 5px;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tags-form-text-area {
  display: none;
}

.tag {
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #CECECE;

  padding: 0 5px;

  .fa-xmark {
    cursor: pointer;
  }
}