.container {
    margin: 20px auto;
    height: 100%;

    .plyr__progress__buffer {
        display: none;
    }

    .plyr__tooltip {
        display: none;
    }

    .plyr__video-embed__container {
        height: 100%;

        iframe {
            height: 100%;
            width: 100%;
        }
    }

    .plyr__video-wrapper {
        iframe {
            height: 100%;
            width: 100%;
        }
    }

    .plyr {
        display: flex;
        flex-direction: column-reverse;
    }

    .plyr__controls {
        display: flex;
        flex-direction: row-reverse;

        .plyr__progress__container {
            width: 100%;

            .plyr__progress {
                input {
                    width: 100%;
                }
            }


        }
    }
}

.container-disabled {
    margin: 20px auto;
    height: 100%;

    .plyr__progress__buffer {
        display: none;
    }

    .plyr__tooltip {
        display: none;
    }

    .plyr__video-embed__container {
        height: 100%;
        iframe {
            height: 100%;
            width: 100%;
        }
    }

    .plyr__video-wrapper {
        iframe {
            height: 100%;
            width: 100%;
        }
    }

    .plyr {
        display: flex;
        flex-direction: column-reverse;
    }

    .plyr__controls {
        display: flex;
        flex-direction: row-reverse;

        .plyr__progress__container {
            width: 100%;

            .plyr__progress {
                pointer-events: none;

                input {
                    width: 100%;
                }
            }


        }
    }
}

.buttons-container{
    padding-top: 10px;

    display: flex;
    justify-content: space-between;
}

.button-wrapper{
    display: flex;
    gap: 10px;
}

.rewind-button{
    .fa-solid{
        margin-right: 5px;

    }
}

.video-player-container {
    width: 100%;
    height: 600px;
}