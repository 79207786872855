.content-wrapper {
    margin: 20px 100px;

    @media(max-width: 899px) {
        padding: 56px 0;
    }

    @media (max-width: 600px) {
        margin: 20px 50px;
    }

    @media (max-width: 500px) {
        margin: 20px 15px;
        .page-footer {
            bottom: 54px;
        }
    }

    .page-footer {
        padding-left: 16px;
    }
}