@import 'assets/scss/_variables.scss';

.ant-select.default-select {
  .ant-select-selector:hover {
    border-color: $button-bg-primary-hover;
  }

  .ant-select-selector {
    border-radius: 8px;
  }
}

.ant-form-item-label {
  white-space: normal;
  overflow: visible;
}
.select-width-100 {
  .ant-form-item-control-input {
    width: 100px;
  }
}

.select-width-200 {
  .ant-form-item-control-input {
    width: 200px;
  }
}