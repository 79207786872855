.notifications-popover {
    .ant-popover-inner {
        border-radius: 8px;
    }
    .ant-popover-title {
        padding: 10px 20px;
    }
    .ant-popover-inner-content {
        padding: 20px 20px;
    }
    .notification-content {
        .ant-alert {
            margin-bottom: 10px;
        }
    }
}
.notifications-dropdown {
    position: relative;

    .notifications-dot {
        width: 9px;
        height: 9px;
        background: #FF4D4F;
        border: 1px solid #FFFFFF;
        border-radius: 100px;
        flex-grow: 0;
        position: absolute;
        right: 5px;
        bottom: 5px;
    }
}
