.certificate-wrapper {
  .ant-radio-button-wrapper {
    margin-right: 10px;
    margin-bottom: 10px;
    height: 100px;
    width: 165px;
    padding: 10px;
    border-radius: 8px;
    border-left-width: 1px;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }

  img {
    width: 110px;
    height: 80px;
    border-radius: 8px;
  }
}

.certificate-background-upload {
  border-radius: 8px;
  border: 1px solid #40a9ff;
  color: #40a9ff;
  height: 100px;
  width: 165px;
  padding: 10px;
}

.delete-icon {
  bottom: 15%;
  left: 80%;
  position: absolute;
}