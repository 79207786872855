
.competences-settings-wrapper {
  .ant-transfer-list {
    width: 350px;
    height: 350px;
  }
}

.request-select {
  width: 400px;
}
