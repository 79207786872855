.ant-table-pagination.ant-pagination {
    text-align: right;
    float: none;

    .ant-pagination-total-text{
        height: 30px;
        float:left;
        text-align: center;
        margin-right: auto;
        font-family: Gilroy;
        font-weight: 600;
    }

    .ant-pagination-item-active {
        border-color: $theme-color!important;
    }
    .ant-pagination-disabled{
        .ant-pagination-item-link{
            .anticon-left{
            color:#BFBFBF!important;
            }
        }
        .ant-pagination-item-link{
            .anticon-right{
            color:#BFBFBF!important;
            }
        }
    }
    .ant-pagination-prev{
        height: 30px;
        .ant-pagination-item-link{
            border-color: #D9D9D9;
            border-radius: 8px;
            width: 30px;
            height: 30px;
            margin: 0px 4px;
            .anticon-left{
                color:#8C8C8C;
                font-family: "Font Awesome 5 Pro";
                font-size: 11px;
                font-weight: 300;
            }
        }
    }
    .ant-pagination-next{
        height: 30px;
        .ant-pagination-item-link{
            border-color: #D9D9D9;
            border-radius: 8px;
            width: 30px;
            height: 30px;
            margin-left:4px;
            .anticon-right{
                color:#8C8C8C;
                font-family: "Font Awesome 5 Pro";
                font-size: 11px;
                font-weight: 300;
            }
        }
    }
    .ant-pagination-item {
        border-color: #D9D9D9;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        margin: 0px 4px;
        a{
            font-size: 14px;
            text-align: center;
            font-family: Gilroy;
            font-weight: 500;
            color: #262626;
        }
    }
    .ant-pagination-options{
        .ant-pagination-options-size-changer.ant-select{
            margin-right: 17px;

            .ant-select-selector{
                border-color: #D9D9D9;
                border-radius: 8px;
                width: 99px;
                height: 30px;
                margin: 0px 4px;
                margin-left: 31px;
                font-family: Gilroy;
                font-weight: 500;
            }
            .anticon-down{
                color:#8C8C8C;
                font-family: "Font Awesome 5 Pro";
                font-size: 12px;
                font-weight: 300;
            }
        }
        .ant-pagination-options-quick-jumper{
            font-family: Gilroy;
            font-weight: 500;
            input{
                border-color: #D9D9D9;
                border-radius: 8px;
                width: 47px;
                height: 30px;
            }
        }
    }
    .ant-pagination-jump-next {
        margin: 0px 4px;
        height: 16px;
        color: #8C8C8C;
        font-family: "Font Awesome 5 Pro";
        font-size: 11px;
        letter-spacing: 0;
        line-height: 16px;
    }
    .ant-pagination-jump-prev {
        margin: 0px 4px;
        height: 16px;
        color: #8C8C8C;
        font-family: "Font Awesome 5 Pro";
        font-size: 11px;
        letter-spacing: 0;
        line-height: 16px;
    }
}